import AboutPattern from "../../assets/about/about_color_pattern.svg";
import AboutRectangle1 from "../../assets/about/about_rectangle_1.svg";
import AboutRectangle2 from "../../assets/about/about_rectangle_2.svg";
import AboutRectangle3 from "../../assets/about/about_rectangle_3.svg";
import AboutRectangle4 from "../../assets/about/about_rectangle_4.svg";
import AboutLargeEcllipse from "../../assets/about/large_ecllipse.svg";
import AboutSmallEcllipse from "../../assets/about/small_ecllipse.svg";

import "./styles.css";

const AboutUs = () => {
  return (
    <div className="about-container">
      <div className="about-header-effect">
        <img
          className="about-large-ecllipse"
          src={AboutLargeEcllipse}
          alt="large ecllispe"
        />
        <img
          className="about-small-ecllipse"
          src={AboutSmallEcllipse}
          alt="large ecllispe"
        />
      </div>
      <div className="about-content-wrapper">
        <div className="about-height-bar"></div>
        <div className="about-header-conetent">
          <div className="about-title-wrapper">
            <span className="about-description">
              <span className="about-title">about us</span>
              <br />
              Young Minds, Seasoned Hands & Skilled Expertise
            </span>
          </div>
          <div className="about-color-pattern">
            <img src={AboutPattern} alt="about pattern" />
          </div>
        </div>
        <div className="about-width-bar"></div>
        <div className="about-content-items-wrapper">
          <div className="about-content-items">
            <div className="about-content-item">
              <span className="about-content-item-content">
                <span className="about-content-item-title">Who WE are</span>
                <br /> <br />A diverse team of skilled developers who share a
                common desire to create perfect IT solutions.{" "}
              </span>
            </div>
            <div className="about-content-item">
              <span className="about-content-item-content">
                <span className="about-content-item-title">
                  Cowork philosophy
                </span>
                <br />
                <br />
                We developed our own philosophy based on three fundamental
                aspects: Focus, Idea, Passion, which help us to meet customer
                expectations and provide the expertise, experience, dedication,
                and flexibility required to design, develop and support digital
                products.{" "}
              </span>
            </div>
            <div className="about-content-item">
              <span className="about-content-item-content">
                <span className="about-content-item-title">How we work</span>
                <br />
                <br />
                Our team is recognized for a 100% sprint completion rate,
                on-time delivery, and stellar quality. Contact us to experience
                the rapid transformation of your idea to a winning MVP.{" "}
              </span>
            </div>
          </div>
          <div className="about-content-img-wrapper">
            <div className="about-content-img">
              <img src={AboutRectangle1} alt="about rectangle" />
              <img src={AboutRectangle2} alt="about rectangle" />
              <img src={AboutRectangle3} alt="about rectangle" />
              <img src={AboutRectangle4} alt="about rectangle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
