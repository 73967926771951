export const CHANGE_STATUS_OF_LOGOS_SECTION = "CHANGE_STATUS_OF_LOGOS_SECTION";
export const CHANGE_STATUS_OF_PEOPLE_SECTION =
  "CHANGE_STATUS_OF_PEOPLE_SECTION";

export const GET_USERS_LIST_REQUEST = "GET_USERS_LIST_REQUEST";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_ERROR = "ADD_USER_ERROR";

export const CHANGE_USERS_ORDER = "CHANGE_USERS_ORDER";

export const SAVE_USERS_ORDER_REQUEST = "SAVE_USERS_ORDER_REQUEST";
export const SAVE_USERS_ORDER_SUCCESS = "SAVE_USERS_ORDER_SUCCESS";
export const SAVE_USERS_ORDER_ERROR = "SAVE_USERS_ORDER_ERROR";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_PORTFOLIOS_LIST_REQUEST = "GET_PORTFOLIOS_LIST_REQUEST";
export const GET_PORTFOLIOS_LIST_SUCCESS = "GET_PORTFOLIOS_LIST_SUCCESS";
export const GET_PORTFOLIOS_LIST_ERROR = "GET_PORTFOLIOS_LIST_ERROR";

export const UPDATE_PORTFOLIO_REQUEST = "UPDATE_PORTFOLIO_REQUEST";
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS";
export const UPDATE_PORTFOLIO_ERROR = "UPDATE_PORTFOLIO_ERROR";

export const ADD_PORTFOLIO_REQUEST = "ADD_PORTFOLIO_REQUEST";
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS";
export const ADD_PORTFOLIO_ERROR = "ADD_PORTFOLIO_ERROR";

export const CHANGE_PORTFOLIOS_ORDER = "CHANGE_PORTFOLIOS_ORDER";

export const SAVE_PORTFOLIOS_ORDER_REQUEST = "SAVE_PORTFOLIOS_ORDER_REQUEST";
export const SAVE_PORTFOLIOS_ORDER_SUCCESS = "SAVE_PORTFOLIOS_ORDER_SUCCESS";
export const SAVE_PORTFOLIOS_ORDER_ERROR = "SAVE_PORTFOLIOS_ORDER_ERROR";

export const DELETE_PORTFOLIO_REQUEST = "DELETE_PORTFOLIO_REQUEST";
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_ERROR = "DELETE_PORTFOLIO_ERROR";

export const GET_CLIENTS_LIST_REQUEST = "GET_CLIENTS_LIST_REQUEST";
export const GET_CLIENTS_LIST_SUCCESS = "GET_CLIENTS_LIST_SUCCESS";
export const GET_CLIENTS_LIST_ERROR = "GET_CLIENTS_LIST_ERROR";

export const ADD_CLIENT_REQUEST = "ADD_CLIENT_REQUEST";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_ERROR = "ADD_CLIENT_ERROR";

export const CHANGE_CLIENTS_ORDER = "CHANGE_CLIENTS_ORDER";

export const SAVE_CLIENTS_ORDER_REQUEST = "SAVE_CLIENTS_ORDER_REQUEST";
export const SAVE_CLIENTS_ORDER_SUCCESS = "SAVE_CLIENTS_ORDER_SUCCESS";
export const SAVE_CLIENTS_ORDER_ERROR = "SAVE_CLIENTS_ORDER_ERROR";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_ERROR = "UPDATE_CLIENT_ERROR";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR";

// Login user
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

// Check token valid
export const CHECK_TOKEN_VALID_REQUEST = "CHECK_TOKEN_VALID_REQUEST";
export const CHECK_TOKEN_VALID_SUCCESS = "CHECK_TOKEN_VALID_SUCCESS";
export const CHECK_TOKEN_VALID_ERROR = "CHECK_TOKEN_VALID_ERROR";

// Send Message
export const SEND_MSG_REQUEST = "SEND_MSG_REQUEST";
export const SEND_MSG_SUCCESS = "SEND_MSG_SUCCESS";
export const SEND_MSG_ERROR = "SEND_MSG_ERROR";
